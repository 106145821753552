export const STORAGE_KEY_TOKEN = "token";
export const STORAGE_KEY_SECRET = "token_secret";
export const STORAGE_KEY_VERIFIER = "token_verifier";

export const DEFAULT_REDIRECT_PATH = '/noaccess';

export enum AUTH_ROLES {
    ARAdmin = 'ARAdmin',
    ARAdminViewMessages = 'ARAdminViewMessages',
}

export const APP_INSIGHTS_ROLE_NAME = "AudioRecall_UI";