import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { APP_INSIGHTS_ROLE_NAME } from '../constants';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
          connectionString: process.env.REACT_APP_INSIGHTS_CONNECTION_STRING,
          enableAutoRouteTracking: true,
          extensions: [reactPlugin],
        }
    }
);
appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
  envelope.tags = envelope.tags || [];
  envelope.tags.push({ "ai.cloud.role": APP_INSIGHTS_ROLE_NAME })
});

if (process.env.NODE_ENV !== "development")
  appInsights.loadAppInsights();

export { reactPlugin };
export { appInsights };